<template>
  <div>
    <v-autocomplete
      v-model="deviceSelected"
      dense
      outlined
      item-text="imei"
      :label="label"
      :items="deviceList"
      return-object
      :readonly="readonly"
      @change="save"
      :filter="filter"
    >
      <template v-slot:selection="data">
        <template>
          {{ data.item.imei }} - {{ data.item.fabricante }} {{ data.item.modelo }}
        </template>
      </template>

      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.imei }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.fabricante }} {{ data.item.modelo }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    selectedDevice: {
      type: [Object, Array],
      default: () => {},
    },
    label: {
      type: String,
      default: 'Aparelho'
    },
    rules: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deviceSelected: {},
      deviceList: [],
      loading: true,
    };
  },
  async mounted() {
    try {
      if (this.selectedDevice && Object.keys(this.selectedDevice).length > 0) {
        this.deviceSelected = this.selectedDevice;
      }
      if (this.readonly) {
        this.deviceList = [this.deviceSelected];
      } else {
        await this.loadDevices();
        this.deviceList = this.devices;
      }
      this.loading = false;
    } catch (e) {
      await this.$swal("Oops...", e.message, "error");
    }
  },
  computed: {
    ...mapState("device", ["devices"]),
  },
  methods: {
    ...mapActions("device", ["loadDevices"]),

    filter: function (item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      return (regExp.test(item.imei) || regExp.test(item.fabricante) || regExp.test(item.modelo));
    },

    save (value) {
      if (!this.readonly) {
        if (!value) value = {};
        this.$emit("save", value);
      }
    }
  },
  watch: {
    selectedDevice(newVal) {
      if (this.deviceSelected !== newVal) {
        this.deviceSelected = newVal;
      }
    }
  },
};
</script>
<style scoped></style>
