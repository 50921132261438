<template>
  <v-card outlined class="pa-2">
    <span>{{default_label_date ? default_label_date : "Data de Finalização"}}</span>
    <DatePicker
      v-model="initialDate"
      value-type="YYYY-MM-DD"
      format="DD/MM/YYYY"
      placeholder="Data de Finalização"
      style="width: 100%; margin-bottom: 1em"
      :disabled="loading"
      title="Data de Finalização"
      @change="setReactivationDate()"
    ></DatePicker>

    <span class="hour-span">{{ default_label_hour ? default_label_hour : "Hora de Finalização" }}</span>
    <DatePicker
      :disabled="loading"
      type="time"
      value-type="HH:mm:ss"
      format="HH:mm"
      placeholder="Hora de Finalização"
      title="Hora de finalização"
      style="width: 100%; margin-bottom: 1em"
      :show-hour="true"
      v-model="finishHour"
      @change="updateFields()"
    ></DatePicker>

    <span v-if="type == 'voluntary_line_suspension' || type == 'suspensao_perda_roubo'"
      >Data de Previsão de Reativação</span
    >
    <DatePicker
      v-model="reactivationDate"
      v-if="type == 'voluntary_line_suspension' || type == 'suspensao_perda_roubo'"
      value-type="YYYY-MM-DD"
      format="DD/MM/YYYY"
      style="width: 100%"
      :disabled="loading"
      placeholder="Data de Previsão de Reativação"
      title="Data de Previsão de Reativação"
      @change="validateReactivationDate()"
    ></DatePicker>
    <span class="showErrorMessages" v-if="showDateLimitRule"
      >A data não pode ser superior a 120 dias</span
    >
    <span class="showErrorMessages" v-if="showRequiredDateRule"
      >A data é obrigatória</span
    >
  </v-card>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  components: { DatePicker },
  props: ["type", "index", "default_hour", "default_date", "default_label_hour", "default_label_date"],
  data() {
    return {
      lineSelect: [],
      loading: true,
      initialDate: "",
      reactivationDate: "",
      validationDate: "",
      finishHour: "",
      showDateLimitRule: false,
      showRequiredDateRule: false
    };
  },

  async mounted() {
    this.reactivationDate = this.getDefaultDate();
    if (this.default_date) {
      this.initialDate = this.default_date;
      this.updateFields();
    }
    if (this.default_hour) {
      this.finishHour = this.default_hour;
      this.updateFields();
    }
    this.loading = false;
  },

  methods: {
    getDefaultDate() {
      if (this.default_date != null) {
        let splitedDate = this.default_date.split("-");
        let defaultFinishDate = new Date(splitedDate[0], splitedDate[1], splitedDate[2]);
        defaultFinishDate.setDate(defaultFinishDate.getDate() + 120);
        this.loading = false;
        return defaultFinishDate
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-");
      }

      return '';
    },

    setReactivationDate() {
      if (this.initialDate != null) {
        let splitedDate = this.initialDate.split("-");
        let formatedDate = new Date(
            splitedDate[0],
            splitedDate[1],
            splitedDate[2]
        );
        formatedDate.setDate(formatedDate.getDate() + 120);
        formatedDate = formatedDate
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "-");
        this.reactivationDate = formatedDate;
        this.validationDate = formatedDate;
        this.showDateLimitRule = false;
      }
      this.updateFields();
    },

    validateReactivationDate() {
      if (this.reactivationDate != "" && this.reactivationDate != null) {
        this.showRequiredDateRule = false;
        if (this.reactivationDate > this.validationDate) {
          this.showDateLimitRule = true;
        } else {
          this.showDateLimitRule = false;
        }
      } else {
        this.showDateLimitRule = false;
        this.showRequiredDateRule = true;
      }
      this.updateFields();
    },

    updateFields() {
      let updateValues = {
        initialDate: this.initialDate,
        finishDate: this.reactivationDate,
        finishHour: this.finishHour,
        index: this.index
      };
      this.$emit("changeDateValues", updateValues);
    }
  }
};
</script>
<style scoped>
.showErrorMessages {
  color: red;
  display: inline-block;
  width: 100%;
  margin-left: 5px;
  font-size: 10px;
}

.hour-span {
  margin-top: 2em;
}
</style>
