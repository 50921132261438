<template>
  <v-col cols="12" class="mx-auto">
    <v-alert
      border="left"
      class="text-center"
      dense
      type="warning"
      v-if="loadingPackage"
      dark
    >Carregando pacotes da linha!
    </v-alert>
    <template v-if="!loadingPackage && lineSelected.packages && lineSelected.packages.length">
      <v-alert border="left" color="indigo" dark dense class="mb-0">
        Pacotes da linha:
      </v-alert>
      <v-list>
        <template v-for="(linePackage, index) in lineSelected.packages">
          <v-list-item :key="linePackage.nome">
            <v-list-item-content>
              <v-list-item-title>
                {{ linePackage.nome }}
                <v-list-item-subtitle v-if="linePackage.valor_assinatura">
                  R$ {{ linePackage.valor_assinatura }}
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider
              v-if="index < lineSelected.packages.length - 1"
              :key="index"
          ></v-divider>
        </template>
      </v-list>
    </template>
    <template v-if="!loadingPackage && lineSelected.line && (!lineSelected.packages || lineSelected.packages.length < 1)">
      <v-alert border="left" color="indigo" dark dense class="mb-0">
        Pacotes da linha:
      </v-alert>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Não possui
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-col>
</template>

<script>
export default {
  props: ["loadingPackage", "lineSelected"],
};
</script>
