<template>
  <div>
    <v-autocomplete
      v-model="lineSelected"
      dense
      outlined
      item-text="nr_linha"
      :label="label"
      :items="lineList"
      return-object
      :readonly="readonly"
      :rules="rules"
      :filter="filter"
      :loading="loading"
      @change="save"
    >
      <template v-slot:selection="data">
        {{ data.item.ddi }} ({{ data.item.ddd }})
        {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
        {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
      </template>

      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.ddi }} ({{ data.item.ddd }})
              {{ data.item.nr_linha }}
            </v-list-item-title>
            <v-list-item-subtitle v-html="data.item.nome_completo"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    selectedLine: {
      type: [Object, Array],
      default: () => {},
    },
    label: {
      type: String,
      default: 'Linha'
    },
    rules: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lineSelected: {},
      lineList: [],
      showPackage: false,
      loading: true,
    };
  },
  async mounted() {
    try {
      if (this.selectedLine && Object.keys(this.selectedLine).length > 0) {
        this.lineSelected = this.selectedLine;
      }
      if (this.readonly) {
        this.lineList = [this.lineSelected];
      } else {
        await this.loadLines();
        this.lineList = this.lines.data;
      }
      this.loading = false;
    } catch (e) {
      await this.$swal("Oops...", e.message, "error");
    }
  },
  computed: {
    ...mapState("packages", ["lines"]),
  },
  methods: {
    ...mapActions("packages", ["loadLines"]),

    filter: function(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
        regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },

    save(value) {
      if (!this.readonly) {
        if (!value) value = {};
        this.$emit("save", value);
      }
    }
  },
  watch: {
    selectedLine(newVal) {
      if (this.lineSelected !== newVal) {
        this.lineSelected = newVal;
      }
    }
  },
};
</script>
<style scoped></style>
